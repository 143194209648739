import React, { useState, useEffect } from 'react';
import "react-dates/lib/css/_datepicker.css";
import { motion } from "framer-motion";
import { useRoutes, navigate } from "hookrouter";
import { TableIcon, GridIcon, AddnewIcon, FilterIcon, DetailsIcon } from '../assets/icons/hiking';

import Chip from '@mui/material/Chip';


export function Tokens() {
  const [isTableView, setIsTableView] = useState(true);
  const [searchTermState, setSearchTermState] = useState('');

  const toggleView = (viewType) => {
    if (viewType === 'grid') {
      setIsTableView(false);
    } else if (viewType === 'table') {
      setIsTableView(true);
    }
  }

  const [tokens, setTokens] = useState([]);


  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "tokens";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTokens(data.data.response);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);


  const getTokenTypeChip = (tokenType) => {
    const tokenTypeInfo = {
      1: { label: 'Production', backgroundColor: '#E1BEE7', textColor: '#4A148C' },
      0: { label: 'Development', backgroundColor: '#BBDEFB', textColor: '#0D47A1' },
    };

    const { label, backgroundColor, textColor } = tokenTypeInfo[tokenType] || { label: tokenType, backgroundColor: 'default', textColor: 'black' };

    return (
      <Chip
        label={label}
        style={{ backgroundColor, color: textColor }}
      />
    );
  };


  //token details
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);

  const tokenDetails = (nonce) => {
    const eid = localStorage.getItem('default');
    const type = "tokens";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    let url = `${protocolpluspath()}admin/getData.php?eid=${eid}&type=${type}&nonce=${nonce}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data:', data);
        if (data.success && data.data.response.length > 0) {
          setSelectedTokenDetails(data.data.response[0].token);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  const PopupTokenDetails = ({ token, onClose }) => {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">Details:</h2>
          {token && (
            <>

              <p><span>Token Title: </span><span>{token.token_title}</span></p>
              <p><span>Token URL: </span><span>{token.token_url}</span></p>
              <p><span>Token Type: </span><span>{token.token_type}</span></p>

              <p><span>Token Status: </span><span>{token.token_status}</span></p>
              <p><span>Created Date: </span><span>{token.created_date}</span></p>
              <p><span>Site Url: </span><span>{token.site_url}</span></p>
              <p><span>Client ID: </span><span>{token.client_id}</span></p>

            </>
          )}
          <div className="flex justify-end mt-6">
            <button className="py-2 px-4 text-white rounded bg-gray-500 hover:bg-gray-700" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container-tokens"
    >
      <div className="header-tokens">
        <h1 className="title-tokens">Tokens</h1>
        <input
          type="text"
          placeholder="Search for a token"
          className="input-tokens"
          value={searchTermState}
          onChange={(e) => setSearchTermState(e.target.value)}
        />
        <button className="filter-button-token">
          Filter By
          <span style={{ marginLeft: '8px' }}>
            <FilterIcon />
          </span>
        </button>

        <div className="view-switch">
          <button
            className={`view-option ${!isTableView ? 'selected' : ''}`}
            onClick={() => toggleView('grid')}
          >
            <GridIcon selected={!isTableView} />
          </button>
          <button
            className={`view-option ${isTableView ? 'selected' : ''}`}
            onClick={() => toggleView('table')}
          >
            <TableIcon selected={isTableView} />
          </button>
        </div>
        <button
          className="add-new-token"
          onClick={() => navigate('enroll')}
        >
          <AddnewIcon className="mr-1" />
          ADD NEW
        </button>
      </div>

      {
        isTableView ? (
          <div className="tokens-table-container">
            <table className="tokens-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Token Name</th>
                  <th>URL</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>State</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((tokenData, index) => (
                  <tr key={index}>
                    <td className="font-semibold text-sm">{index + 1}</td>
                    <td className="font-semibold text-sm">{tokenData.token.token_title}</td>
                    <td className="font-semibold text-sm">{tokenData.token.site_url}</td>
                    <td className="font-semibold text-sm">{getTokenTypeChip(tokenData.token.token_type)}</td>
                    <td className="font-semibold text-sm">{tokenData.token.token_status}</td>
                    <td>--</td>
                    <td>
                      <a
                        onClick={() => tokenDetails(tokenData.token.nonce)}
                        className="flex font-semibold items-center space-x-1 cursor-pointer text-sm"
                      >
                        <DetailsIcon className="w-4 h-4" />
                        <span>Details</span>
                      </a>
                    </td>
                  </tr>

                ))}
              </tbody>
            </table>

            {selectedTokenDetails && <PopupTokenDetails token={selectedTokenDetails} onClose={() => setSelectedTokenDetails(null)} />}
          </div>
        ) : (
          <div className="tokens-grid">
            {tokens.map((tokenData, index) => (
              <div className="token-card" key={index}>
                <span className="state-label-token">State</span>
                <h2 className="token-name">{tokenData.token.token_title}</h2>
                <p className="token-url">{tokenData.token.site_url}</p>
                <hr className="separator-line" />
                <div>
                  <span>{getTokenTypeChip(tokenData.token.token_type)}</span>
                </div>
                <div className="token-status">
                  {tokenData.token.token_status}
                </div>
              </div>
            ))}
          </div>
        )
      }
    </motion.div >
  );
}
