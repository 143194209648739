import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import CodeMirror from '@uiw/react-codemirror';
import 'react-quill/dist/quill.snow.css';
// import { InfoIcon } from './assets/icons/hiking';
import { useNotifications } from './NotificationContext';

import prettier from 'prettier/standalone';
import parserHtml from 'prettier/parser-html';


export function Settings() {
  const { showNotification } = useNotifications();
  const [editorHtml, setEditorHtml] = useState('');
  const [codeEditorHtml, setCodeEditorHtml] = useState('');
  const [isTextEditorVisible, setIsTextEditorVisible] = useState(true);



  function handleChange(html) {
    setEditorHtml(html);
  }

  const handleCodeChange = (value, viewUpdate) => {
    setCodeEditorHtml(value);
  };



  function submitText() {
    const eid = localStorage.getItem('default');
    const contentToSend = isTextEditorVisible ? editorHtml : codeEditorHtml;
    const payload = {
      eid: eid,
      endpoint: 'v1/wysiwyg/save',
      wysiwyg_type: 'wysiwyg',
      wysiwyg_text: contentToSend
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response === "OK") {

          const Notificationpayload = {
            response: true,
            subject: "Text",
            operation: "Updated",
            context: "",
          };
          showNotification(Notificationpayload);
        } else {
          const Notificationpayload = {
            response: false,
            subject: "Text",
            operation: "Updating",
            context: "",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Text",
          operation: "Updating",
          context: "",
        };
        showNotification(Notificationpayload);
      });
  };

  useEffect(() => {
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=wysiwyg';

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(responseText => {
        try {
          const data = JSON.parse(responseText);

          if (data && data.data && data.data.success && Array.isArray(data.data.response)) {
            const filteredData = data.data.response.filter(item => item.id === 0);
            if (filteredData.length > 0) {
              const decodedHtml = decodeHtml(filteredData[0].wysiwyg.wysiwyg_text);
              const formattedHtml = formatHtml(decodedHtml);
              setEditorHtml(formattedHtml);
              setCodeEditorHtml(formattedHtml);
            }
          } else {
            throw new Error('No data returned or the operation was unsuccessful');
          }
        } catch (error) {
          if (responseText.includes('Undefined index: default_api')) {
            window.location.href = '/logout';
          } else {
            throw error;
          }
        }
      })
      .catch(error => {
        console.error("Error processing data:", error);
      });
  }, []);


  function formatHtml(html) {
    return prettier.format(html, { parser: "html", plugins: [parserHtml] });
  }

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  function toggleEditor() {
    setIsTextEditorVisible(!isTextEditorVisible);
  }

  return (
    <div className="px-10">

      <div className="flex-parent flex justify-between px-3">
        <h1 className="settings-title">Settings</h1>
      </div>


      <div className="flex justify-between items-center py-6">
        <div className="flex items-center">
          {/* <InfoIcon /> */}
          <p className="text-xs italic text-red ml-2">Note - please Do Not introduce HTML or other code in the text editor.</p>
        </div>
        <button onClick={toggleEditor} className="bg-gray-500 text-white px-4 py-2 rounded">
          {isTextEditorVisible ? 'Switch to Code Editor' : 'Switch to Text Editor'}
        </button>
      </div>



      <div className="flex flex-col">
        {isTextEditorVisible ? (
          <ReactQuill value={editorHtml} onChange={handleChange} />
        ) : (
          <CodeMirror
            value={codeEditorHtml}
            onChange={handleCodeChange}
            options={{
              mode: 'htmlmixed',
              theme: 'default',
            }}
          />
        )}
      </div>



      <div className="flex justify-between items-center mt-6">
        <div>
          <button onClick={submitText} className="bg-blue-500 text-white px-6 py-2 rounded">
            Submit
          </button>
        </div>
      </div>

    </div>
  );
}
