import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { navigate } from "hookrouter";


export function Redirect() {


    useEffect(() => {
        localStorage.clear();
        document.cookie.split(';').forEach((c) => {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });


        const timer = setTimeout(() => {
            navigate("/login");
        }, 3000);

        return () => clearTimeout(timer);
    }, [history]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="flex-parent flex justify-between px-3">
                <div className="absolute top-0 left-0 m-4">
                    <p>Access Denied or session.. You are being logged out... redirecting</p>
                </div>
            </div>
        </motion.div>
    );
}
