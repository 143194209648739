import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { Divider, Steps } from 'antd';

export default function Testing() {


    useEffect(() => {
        const eid = localStorage.getItem('default');

        const type = "token_refresh";
        const client_id = "TRC4R_DEV~Nuvagis7KyJ39ESJSnzFP6cpFjHpwCccI5z_2IPTuLQ"

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&client_id=' + client_id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log('Data fetched:', data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);


    const steps = ['', '', ''];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleReset = () => {
        setActiveStep(0);
    };



    // ant desing

    const [current, setCurrent] = useState(0);
    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };
    const description = 'This is a description.';


    return (


        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div >
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            // if (isStepOptional(index)) {
                            //     labelProps.optional = (
                            //         <Typography variant="caption">Optional</Typography>
                            //     );
                            // }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />


                                <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>


                <Steps
                    current={current}
                    onChange={onChange}
                    items={[
                        {
                            title: 'Step 1',
                            description,
                        },
                        {
                            title: 'Step 2',
                            description,
                        },
                        {
                            title: 'Step 3',
                            description,
                        },
                    ]}
                />

                <Divider />


                <Button
                    variant="contained"
                    style={{ backgroundColor: 'rgb(230, 44, 62)', fontWeight: 520 }}
                >
                    Save & ContinuE
                </Button>





            </div>
        </motion.div >
    );

}


