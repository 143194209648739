import React, { useState } from 'react';
import { motion } from "framer-motion";
import { InfoIcon } from "../assets/icons/hiking";
import { Divider, Steps } from 'antd';
import { useNotifications } from './NotificationContext';
import { Box, Button, CircularProgress } from '@mui/material';

export default function Enroll() {
  const { showNotification } = useNotifications();
  const [errorMsg, setErrorMsg] = useState('');
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [step, setStep] = useState(0);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;

    if (value.split(' ').filter(word => word).length > 50) {
      setErrorMsg('Your description should not exceed 50 words.');
    }
  };

  const nextStep = () => {
    if (step < 2) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  };


  function CircleWithRing({ isActive, children }) {
    return (
      <div className="circle-container">
        <div className={isActive ? "circle active" : "circle"}>{children}</div>
        {isActive && <div className="ring2"></div>}
      </div>
    );
  }



  // 1st page
  const [token_title, setTokenTitle] = useState('');
  const [site_url, setSiteUrl] = useState('');
  const [expiration_date, setExpirationDate] = useState('');
  const [token_description, setTokenDescription] = useState('');

  // 2nd page




  //3rd page 
  const [client_id, setClientId] = useState('');
  const [client_secret, setClientSecret] = useState('');
  const [access_token, setAccesstoken] = useState('');
  const [refresh_token, setRefreshToken,] = useState('');
  const [id_token, setIdToken] = useState('');
  const [token_type, setTokenType] = useState('');
  const [token_url, setTokenUrl] = useState(''); // redirect url


  const handleSubmit = () => {

    const eid = localStorage.getItem('default');

    const payload = {

      // 1st page
      token_title,
      site_url,
      expiration_date,
      token_description,
      //2nd

      //Ln_user_id
      //IONAPI File Contents

      // 3rd page
      client_id,
      client_secret,
      access_token,
      refresh_token,
      id_token,
      token_type,
      token_url, // redirect url

      eid: eid,
      endpoint: 'v1/bridge/ln/token/add',
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response && data.response.nonce) {
          const Notificationpayload = {
            response: true,
            subject: "Token",
            operation: "Created",
            context: "",
          };


          setTokenTitle('');
          setSiteUrl('');
          setExpirationDate('');
          setTokenDescription('');
          setClientId('');
          setClientSecret('');
          setAccesstoken('');
          setRefreshToken('');
          setIdToken('');
          setTokenType('');
          setTokenUrl('');

          showNotification(Notificationpayload);

        } else {
          const Notificationpayload = {
            response: false,
            subject: "Token",
            operation: "Creating",
            context: "",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Token",
          operation: "Creating",
          context: "",
        };

        showNotification(Notificationpayload);
      });
  }



  //
  const [jsonContent, setJsonContent] = useState('');
  const [isJsonValid, setIsJsonValid] = useState(false);

  const handleJsonChange = (event) => {
    const content = event.target.value;
    setJsonContent(content);

    try {
      const json = JSON.parse(content);
      setClientId(json.ci || '');
      setClientSecret(json.cs || '');
      setTokenUrl(json.ru || '');
      setIsJsonValid(true);
    } catch (error) {
      setIsJsonValid(false);
    }
  };

  // fetching the refreshToken

  const [loading, setLoading] = useState(false);


  const generateRefreshToken = () => {
    setLoading(true);
    const eid = localStorage.getItem('default');
    const type = "token_refresh";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&client_id=' + client_id;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          const { access_token, client_secret } = data.data.response;

          const Notificationpayload = {
            response: true,
            subject: "Refresh Token",
            operation: "Created",
            context: "",
          };

          showNotification(Notificationpayload);

          setAccesstoken(access_token || '');
          setRefreshToken(client_secret || '');
        }
      })
      .catch(error => {
        const Notificationpayload = {
          response: false,
          subject: "Refresh Token",
          operation: "Creating",
          context: "",
        };
        showNotification(Notificationpayload);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-center w-full min-h-screen pt-10"
    >


      <div className="container mx-auto p-6 custom-width">

        <div className="flex items-center mb-6 space-x-4 -mt-5">
          <CircleWithRing isActive={step === 0}>1</CircleWithRing>
          <div className="flex-grow h-px bg-gray-300"></div>
          <CircleWithRing isActive={step === 1}>2</CircleWithRing>
          <div className="flex-grow h-px bg-gray-300"></div>
          <CircleWithRing isActive={step === 2}>3</CircleWithRing>
        </div>

        {step === 0 && (
          <div>
            <div className="flex justify-between py-6">
              <div>
                <h1 className="token-title -mt-3">Generate a New Token</h1>
                <span>
                  Complete the three pages of this form to make a new token available for communicating with Infor LN.
                  You should already have a login for the Mingle Portal with access to the API gateway area before you begin.
                  You should also already have an ION App created for use, configured with a return URL defined.
                </span>
              </div>
            </div>

            <div className='py-2'>
              <div className="flex justify-between items-center mb-2 ">
                <label className="text-sm font-semibold">ION Authorized Apps Name</label>

                <span className="cursor-pointer relative"
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}>
                  <InfoIcon />
                  <div className={`absolute bottom-full right-0 mb-1 p-2 w-auto whitespace-nowrap bg-white border border-gray-300 text-black text-xs rounded shadow-lg transition-opacity duration-150 ${isTooltipVisible ? 'opacity-100 visibility-visible' : 'opacity-0 visibility-hidden'} z-50`}>
                    Create a simple name that describes where the token will be used.
                  </div>
                </span>
              </div>

              <div>
                <input
                  placeholder="EX: TECH US ECOM"
                  className="w-full input-style mb-1"
                  value={token_title}
                  onChange={(e) => setTokenTitle(e.target.value)}
                />
                <span className="text-sm mt-1">
                  Use the name of the Authorized App from within the API Gateway that this Token will use for interacting with Infor LN.
                </span>
              </div>
            </div>


            <div className='py-2'>
              <label className="text-sm font-semibold block">Site URL</label>

              <input
                placeholder="Paste full working URL to site here"
                className="mb-2 w-full input-style"
                value={site_url}
                onChange={(e) => setSiteUrl(e.target.value)}
              />
            </div>


            <div className='py-2'>
              <label className="text-sm font-semibold">Expiration Date</label>
              <input
                placeholder="YYYY-MM-DD (Leave Blank for No Exp)"
                className="mb-2 w-full input-style"
                value={expiration_date}
                onChange={(e) => setExpirationDate(e.target.value)}
              />
            </div>

            <div className='py-2'>
              <label className="text-sm font-semibold">Description</label>
              <textarea
                placeholder="Maximum of 50 words"
                className="mb-2 w-full input-style textarea-style"
                value={token_description}
                onChange={(e) => {
                  handleDescriptionChange(e);
                  setTokenDescription(e.target.value);
                }}
              ></textarea>
              <p className="text-red-500">{errorMsg}</p>
            </div>

            <div className="flex justify-end mt-2">
              {/* <button className="save-and-continue text-sm font-semibold" >SAVE & CONTINUE</button> */}

              <Button
                variant="contained"
                onClick={nextStep}
                style={{ backgroundColor: 'rgb(230, 44, 62)', fontWeight: 520 }}
              >
                SAVE & CONTINUE
              </Button>

            </div>


          </div>
        )}
        {step === 1 && (
          <div>

            <div className="flex justify-between py-6">
              <div>
                <h1 className="token-title -mt-3">Generate IONAPI file</h1>
                <span>
                  From within the Authorized App you will download credentials for populating the content of this page.
                </span>
              </div>
            </div>

            <div className='py-2'>
              <label className="text-sm font-semibold block">LN User ID</label>
              <span>
                Enter the email address associated with the LN ID that will be used to generate IONAPI file
              </span>


              <input
                placeholder="EX: support@radialstudios.com"
                className="w-full input-style"
              />
            </div>

            <div className='py-2'>
              <label className="text-sm font-semibold block">IONAPI File Contents</label>
              <span>Once you have downloaded the .IONAPI file, paste the content here into the field below</span>
              <textarea
                className="mb-2 w-full h-32 p-2 resize-none border border-gray-300 rounded-md"
                value={jsonContent}
                onChange={handleJsonChange}
              ></textarea>
            </div>

            <div className="flex justify-between mt-2">
              <button className="previous-step text-sm font-semibold" onClick={prevStep}>PREVIOUS</button>
              <Button
                variant="contained"
                onClick={nextStep}
                disabled={!isJsonValid}
                sx={{ backgroundColor: 'rgb(230, 44, 62)', fontWeight: 600 }}
              >
                SAVE & CONTINUE
              </Button>
            </div>


          </div>
        )}
        {step === 2 && (
          <div>

            <div className="flex justify-between py-6">
              <div>
                <h1 className="token-title -mt-3">Token Parameters</h1>

              </div>
            </div>

            <label htmlFor="tokenName" className="text-sm font-semibold">Client ID</label>
            <input
              placeholder="---"
              value={client_id}
              onChange={(e) => setClientId(e.target.value)}

              className="w-full input-style"
            />

            <label htmlFor="tokenName" className="text-sm font-semibold">Client Secret</label>
            <input
              placeholder="---"
              value={client_secret}
              onChange={(e) => setClientSecret(e.target.value)}
              className="w-full input-style"
            />

            <label htmlFor="tokenName" className="text-sm font-semibold">Redirect URL</label>
            <input
              placeholder="---"
              value={token_url}
              onChange={(e) => setTokenUrl(e.target.value)}
              className="mb-2 w-full input-style"
            />

            <label htmlFor="tokenName" className="text-sm font-semibold">Token Type</label>

            <select
              value={token_type}
              onChange={(e) => setTokenType(e.target.value)}
              className="mb-2 w-full input-style"
            >
              <option value="">---</option>
              <option value="Web">Web</option>
              <option value="Backend">Backend</option>

            </select>

            {/*                    spacing from here                */}

            <div className="relative p-6 bg-gray-200 rounded-lg shadow-lg my-9">
              <div className="flex justify-end">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button
                    onClick={generateRefreshToken}
                    variant="contained"
                    sx={{
                      backgroundColor: client_id ? 'rgb(230, 44, 62)' : 'grey',
                      color: 'white',
                      padding: '0.5rem 1rem',
                      borderRadius: '0.5rem',
                      transition: 'background-color 0.3s',
                      fontWeight: 600,
                      '&:disabled': {
                        backgroundColor: 'lightgrey',
                        color: 'white',
                      },
                    }}
                    disabled={!client_id || loading}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Generate Refresh Token'}
                  </Button>
                </Box>
              </div>

              <div className="mb-4">
                <label htmlFor="accessToken" className="text-sm font-semibold text-gray-700">
                  Access Token
                </label>
                <input
                  id="accessToken"
                  placeholder="---"
                  value={access_token}
                  onChange={(e) => setAccesstoken(e.target.value)}
                  className="mb-2 w-full input-style p-2 bg-gray-300 text-gray-700 border border-gray-400 rounded shadow-inner"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="idToken" className="text-sm font-semibold text-gray-700">
                  Id Token
                </label>
                <input
                  id="idToken"
                  placeholder="---"
                  value={id_token}
                  onChange={(e) => setIdToken(e.target.value)}
                  className="mb-2 w-full input-style p-2 bg-gray-300 text-gray-700 border border-gray-400 rounded shadow-inner"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="refreshToken" className="text-sm font-semibold text-gray-700">
                  Refresh Token
                </label>
                <input
                  id="refreshToken"
                  placeholder="---"
                  value={refresh_token}
                  onChange={(e) => setRefreshToken(e.target.value)}
                  className="mb-2 w-full input-style p-2 bg-gray-300 text-gray-700 border border-gray-400 rounded shadow-inner"
                />
              </div>
            </div>











            <div className="flex justify-between mt-2">

              <button className="previous-step text-sm font-semibold" onClick={prevStep}>PREVIOUS</button>

              <button
                className="mt-2 save-and-continue text-sm font-semibold"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>



          </div>
        )}

      </div>
    </motion.div >
  );
}
