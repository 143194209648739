import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { TokenIcon } from '../assets/icons/hiking';
export function Overview() {

  const [isLoading, setIsLoading] = useState(false);
  const [ipAccessData, setIpAccessData] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "tokens";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTokens(data.data.response);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const type = "wysiwyg";
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=' + type;
    fetch(url)
      .then(response => {
        console.log('Response received');
        if (!response.ok) {
          throw new Error('The network response was not ok');
        }
        return response.text();
      })
      .then(responseText => {
        try {
          const data = JSON.parse(responseText);

          if (data && data.data && data.data.success && Array.isArray(data.data.response)) {
            const filteredData = data.data.response.filter(item => item.id === 0);
            if (filteredData.length > 0) {
              const wysiwygData = filteredData.map(item => ({
                text: item.wysiwyg.wysiwyg_text,
                type: item.wysiwyg.wysiwyg_type
              }));
              setIpAccessData(wysiwygData);
            }
          } else {
            throw new Error('No data returned or the operation was unsuccessful');
          }
        } catch (error) {
          if (responseText.includes('Undefined index: default_api')) {
            window.location.href = '/logout';
          } else {
            throw error;
          }
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      })
      .finally(() => setIsLoading(false));
  }, []);




  function InfoBox({ title, number, icon }) {
    return (
      <div className="box-content relative p-4">
        <h2 className="absolute top-4 left-4">{title}</h2>
        {icon && <div className="absolute top-4 right-4">{icon}</div>}
        <div className="box-overview-number absolute bottom-4 left-4 text-xl">{number}</div>
      </div>
    );
  }

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex-parent flex justify-between px-3 ">
        <div>
          <div className="flex-parent flex justify-between px-3 ">
            <h1 className="overview-title">Welcome back,  {localStorage.getItem('username')}!</h1>
            <span>Create, view and manage the Infor LN refresh tokens.</span>
          </div>

          <div className="flex justify-between mt-4 py-2">
            <InfoBox
              title="Active Tokens"
              number={loading ? "..." : tokens.length}
              className="ml-1 mr-1"
            />

          </div>

          <div className="text-overview-container">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ul>
                {ipAccessData.map((item, index) => (
                  <li key={index}>
                    <div className="unstyled-content">
                      <div dangerouslySetInnerHTML={{ __html: decodeHtml(item.text) }} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>


        </div>
      </div>
    </motion.div >
  );

}


