import React from "react";
import { useRoutes } from "hookrouter";
import { NavItem } from "./components";
import { Overview } from "./overview";

import { Tokens } from "./tokens";
import { Settings } from "./settings";
import { AnimatePresence, motion } from "framer-motion";
import { Logout, Users as UsersIcon, } from "heroicons-react";
import { logout } from "../utils/fetch";
import Enroll from "./enroll";
import { IconEnroll, IconSetting, IconOverview, IconTag } from "../assets/icons/hiking";
import "react-toastify/dist/ReactToastify.css";
import Testing from "./testing";
import Login from "../login";

const routes = {
  "/": () => <Login />,
  "/overview": () => <Overview />,
  "/tokens": () => <Tokens />,
  "/settings": () => <Settings />,
  "/enroll": () => <Enroll />,
  "/testing": () => <Testing />,

};


export default function Main() {
  const routeResult = useRoutes(routes);

  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <div className="fixed h-full pr-10 pb-6">
        <div className="flex flex-col justify-between w-56 h-full text-white rounded-lg bg-firefly">
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img className="w-3/5 mb-4" src={`${process.env.PUBLIC_URL}/TRC_4r-logo_white.webp`} />
            <p className="text-xs italic text-red">{localStorage.getItem('username')} </p>
            <div className="px-1 py-0.5 bg-red-600 text-sm text-white font-semibold cursor-default">
              token manager
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60">
            <motion.ul className="flex flex-col">
              <NavItem linkTo="/app/overview" selected={routeResult.type === Overview}>
                <IconOverview className="-ml-3 mr-2" />

                Overview
              </NavItem>

              <NavItem linkTo="/app/tokens" selected={routeResult.type === Tokens}>
                <IconTag className="-ml-3 mr-2" />
                Tokens
              </NavItem>

              <NavItem linkTo="/app/enroll" selected={routeResult.type === Enroll}>
                <IconEnroll className="-ml-3 mr-2" />
                Enroll
              </NavItem>

              {localStorage.getItem('canManageSettings') === "true" && (
                <NavItem linkTo="/app/settings" selected={routeResult.type === Settings}>
                  <IconSetting className="-ml-3 mr-2" />
                  Settings
                </NavItem>
              )}

            </motion.ul>
          </div>
          <div className="p-6 text-center">
            <div onClick={logout} className="flex justify-center cursor-pointer">
              <Logout className="pr-2" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 text-gray-800" style={{ marginLeft: "244px" }}>
        {/* <Input disabled placeholder="Search Anything Here..." /> */}
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>
      </div>
    </div>
  );

}




